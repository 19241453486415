<modal-header [header]="header" [dialogRef]="dialogRef"></modal-header>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <app-input-basic label="Event Title" type="text" [stacked]="true" [form]="form" name="title">
      </app-input-basic>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-autocomplete type="select" label="Enter Client Name" [form]="form" name="clientNo" [optionsFunc]="
            calendarS.searchClientByName
          " [stacked]="true" valueField="code" labelType="ct" #clientNo>
      </app-autocomplete>
      <!-- <app-validation-message [input]="clientNo"/> -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-input-basic label="Event Type" type="select" labelType="ct" valueField="code"
        [options]="codeS.getCodesByCodeSubGroup | functionCaller1: 'EVENT_TYPE' | async" [stacked]="true" [form]="form"
        name="eventType">
      </app-input-basic>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-input-basic label="Event Place" type="text" [stacked]="true" [form]="form" name="eventPlace">
      </app-input-basic>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-input-basic label="Note" type="textarea" [stacked]="true" [form]="form" name="note">
      </app-input-basic>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <app-input-basic label="Event On" type="datetime-local" [stacked]="true" [form]="form" name="eventOn">
      </app-input-basic>
    </div>
    <!-- <div class="col-md-6">
      <app-input-basic label="End" [hidden]="allDayTag.valueSignal()" type="datetime-local" [stacked]="true" [form]="form"
        name="endDateTime">
      </app-input-basic>
    </div>  -->
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-input-basic label="All Day?" type="checkbox" [stacked]="true" [form]="form" name="allDay" #allDayTag>
      </app-input-basic>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="w-100">
    @if (!event) {
    <div class="row justify-content-end">
      <div class="col-md-auto">
        <app-btn [form]="form" type="secondary" text="Create Event" icon="add" (mclick)="create(btn)" #btn></app-btn>
      </div>
    </div>
    }
    @if (event && data.edit) {
    <div class="row gx-2 justify-content-end">
      <div class="col-md-auto">
        <app-btn type="danger-outline" text="Delete" icon="delete" (mclick)="delete()"></app-btn>
      </div>
      <div class="col-md-auto">
        <app-btn [form]="form" type="secondary" text="Update" icon="save"></app-btn>
      </div>
    </div>
    }
  </div>
</div>
