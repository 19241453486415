import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { UtilityService } from '@Services/utility.service';
import { configForms } from '@Shared/models/form.class';
import { EvoCalendarEvent } from '../add-event/event.interface';
import { EventColor } from 'calendar-utils';
import { CalendarService } from '../calendar.service';
import { CodeService } from '@Services/code.service';
import { lastValueFrom } from 'rxjs';
import { SalesAppService } from 'projects/evolutics-sales-ui/src/app/services/sales-app.service';
import moment from 'moment';
import { AsyncPipe } from '@angular/common';
import {
  BtnComponent,
  AutocompleteComponent,
  InputBasicComponent,
  FunctionCaller1,
} from 'ets-fe-ng-sdk';
import { ModalHeaderComponent } from '../../../../../../evolutics-shared-lib/src/lib/Shared/components/modal-header/modal-header.component';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalHeaderComponent,
    MatDialogContent,
    InputBasicComponent,
    AutocompleteComponent,
    MatDialogActions,
    BtnComponent,
    AsyncPipe,
    FunctionCaller1,
  ],
})
export class AddEventComponent implements OnInit {
  loading: boolean;
  header: string;
  form = new FormGroup({
    title: configForms.required(),
    clientNo: configForms.required(),
    eventType: configForms.default(),
    eventPlace: configForms.default(),
    note: configForms.default(),
    eventOn: configForms.required(),
    //endDateTime: configForms.default(),
    allDay: configForms.boolean(),
  });
  event: EvoCalendarEvent;

  constructor(
    public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uS: UtilityService,
    public calendarS: CalendarService,
    public codeS: CodeService,
    public sApp: SalesAppService,
  ) {}

  ngOnInit(): void {
    this.event = this.data.event ? this.data.event : null;
    this.header =
      this.event && this.data.edit
        ? 'Edit Event'
        : this.event && !this.data.edit
          ? `Showing Event - ${this.event.title}`
          : 'Add New Event';
    if (this.event) {
      const _start = new Date();
      _start.setMinutes(_start.getMinutes() - _start.getTimezoneOffset());

      const _end = new Date();
      _end.setMinutes(_end.getMinutes() - _end.getTimezoneOffset());

      this.event.startDateTime = _start.toISOString().slice(0, 16);
      this.event.endDateTime = _end.toISOString().slice(0, 16);
      this.form.patchValue({ ...this.event, note: this.event.description });
    }
    if (this.data.startDate) {
      // const d = new Date();
      // const dateTimeLocalValue = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .slice(0, -1);
      this.form.patchValue({
        eventOn: moment(this.data.startDate).format('YYYY-MM-DDTHH:MM'),
      });
    }
    if (this.event && !this.data.edit) this.form.disable();
  }

  async create(btn: BtnComponent) {
    btn.setLoader(true);
    try {
      this.loading = true;
      const res = await lastValueFrom(
        this.calendarS.createEvent({
          ...this.form.value,
          eventOn: this.uS.toJavaDatetimeString(this.form.value.eventOn),
          agentNo: this.sApp.webUser.agentNo,
        }),
      );
      const newEvent = {
        ...this.form.value,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      };
      this.dialogRef.close(newEvent);
    } catch (error) {
      this.uS.info(error, 0);
      btn.setLoader(false);
    }
    btn.setLoader(false);
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    this.uS.confirm('Are you sure you want to delete this Event?');
  }
}
