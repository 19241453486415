import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@Services/api.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  baseURL = environment.apiBaseUrl + '/rest/';
  constructor(public apiService: ApiService) {}

  getAllClientNoAndFullName = () => {
    return this.apiService.get(this.baseURL + 'client/clientNo/fullName');
  };

  searchClientByName = (name: string) => {
    return this.apiService
      .get(this.baseURL + 'client/search/', {
        name,
      })
      .pipe(
        map((res) =>
          res.content.map((c: any) => {
            return {
              code: c.clientNo,
              title: c.fullName,
            };
          })
        )
      );
  };

  /**
   * Calls the endpoint that creates a calendar event
   * @param data
   * @returns
   */
  createEvent = (data: any) => {
    return this.apiService.post(this.baseURL + 'agent/calender', data);
  };

  /**
   * Calss the endpoint that search for calender events
   * @param query
   * @returns
   */
  getEvents(query: any) {
    return this.apiService
      .get(this.baseURL + 'agent/list/calender/search', query)
      .pipe(map((res) => res.content as any[]));
  }
}
